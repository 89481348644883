import { graphql } from "gatsby"
import React from "react"
import { Layout } from "../components/Layout/Layout"
import { PageTitle } from "../components/PageTitle/PageTitle"

interface PriceListProps {
  data: {
    cms: {
      page: {
        priceListFields: {
          image: {
            sourceUrl: string
          }
        }
        title: string
      }
    }
  }
}

export const PriceList: React.FC<PriceListProps> = ({ data }) => {
  return (
    <Layout withPadding>
      <div className="flex w-full flex-col items-center overscroll-x-auto">
        <PageTitle>{data.cms.page.title}</PageTitle>
        <div className="bg-white">
          <img src={data.cms.page.priceListFields.image.sourceUrl} alt="" />
        </div>
      </div>
    </Layout>
  )
}

export default PriceList

export const query = graphql`
  {
    cms {
      page(id: "/pricelist", idType: URI) {
        priceListFields {
          image {
            sourceUrl
          }
        }
        title
      }
    }
  }
`
